import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Expertise`}</h2>
    <ProjectCard title="PLC programming" link="https://www.lekoarts.de/gatsby/tips-and-tricks-for-gatsby?utm_source=cara&utm_medium=Theme" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  <ul>
    <li>TIA Portal (Siemens)</li>
    <li>Studio 5000 (Rockwell)</li>
    <li>Codesys</li>
  </ul>
    </ProjectCard>
    <ProjectCard title="HMI development" link="https://www.lekoarts.de/javascript/setting-up-a-yarn-workspace-with-typescript-eslint-and-cypress?utm_source=cara&utm_medium=Theme" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  <ul>
    <li>WinCC Advanced (Siemens)</li>
    <li>WinCC Unified (Siemens)</li>
    <li>FactoryTalk View (Rockwell)</li>
    <li>View Designer (Rockwell)</li>
  </ul>
    </ProjectCard>
    <ProjectCard title="Robots" link="https://www.lekoarts.de/garden/adding-a-draft-feature-to-gatsby?utm_source=cara&utm_medium=Theme" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  <ul>
    <li>Epson</li>
    <li>ABB</li>
    <li>Universal Robots</li>
  </ul>
    </ProjectCard>
    <ProjectCard title="Web development" link="https://www.lekoarts.de/design/introducing-the-theme-ui-plugin-for-figma?utm_source=cara&utm_medium=Theme" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  <ul>
    <li>JavaScript</li>
    <li>React.js</li>
    <li>Redux</li>
    <li>SVG</li>
  </ul>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      