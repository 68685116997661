import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`If you need help to retrofit your code or program the newly built machine, send us an `}<a parentName="p" {...{
        "href": "mailto:office@techsolid.io"
      }}>{`email`}</a>{`, and we will get back to you ASAP.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      